import { createSlice } from "@reduxjs/toolkit";
export const huaySlice = createSlice({
  name: "datahuay",
  initialState: {
    huaylist: [],
    bgimgLobby: "",
    bgimglogin: "",
    lottoStock: [],
    lottoResultYeekee: [],
  },
  reducers: {
    setDataHuays: (state, action) => {
      state.huaylist = action.payload;
    },
    setimgLobby: (state, action) => {
      state.bgimgLobby = action.payload;
    },
    setimgbglogin: (state, action) => {
      state.bgimglogin = action.payload;
    },
    delLastHuay: (state, action) => {
      state.huaylist.splice(action.payload);
    },
    delIndexHuay: (state, action) => {
      let delDataIndex = state.huaylist.findIndex(
        (item) =>
          item.huay_select === action.payload.huay_select &&
          item.number === action.payload.number
      );
      state.huaylist.splice(delDataIndex, 1);
    },
    delDuplicatesHuay: (state, action) => {
      let select = action.payload[0].huay_select;
      if (select.find((item) => item === "2switch" || item === "3switch")) {
        select.splice(
          select.findIndex((item) => item === "2switch" || item === "3switch"),
          1
        );
      } else {
        // newData.push("2switch");
      }
      // let delDataIndex = state.huaylist.findIndex(
      //   (item) =>
      //     item.huay_select === action.payload[0].huay_select &&
      //     item.number === action.payload[0].number
      // );
      for (let item of select) {
        if (state.huaylist) {
          let filter = state.huaylist.findIndex(
            (data) =>
              data.huay_select === item &&
              data.number === action.payload[0].number
          );
          if (filter > -1) {
            state.huaylist.splice(filter, 1);
            state.huaylist = state.huaylist;
          } else {
            state.huaylist((prev) => [
              ...prev,
              { huay_select: item, number: action.payload[0].number },
            ]);
          }
        }
      }

      // console.log("delDataIndex : ", delDataIndex);
      // state.huaylist.splice(delDataIndex, 1);
    },
    setReduxLottoStock: (state, action) => {
      state.lottoStock = action.payload;
    },
    setReduxLottoResultYeekee: (state, action) => {
      state.lottoResultYeekee = action.payload;
    },
  },
});

export const {
  setDataHuays,
  delLastHuay,
  delIndexHuay,
  delDuplicatesHuay,
  setimgLobby,
  setimgbglogin,
  setReduxLottoStock,
  setReduxLottoResultYeekee,
} = huaySlice.actions;
export const getHuay = (state) => state.datahuay.huaylist;
export const getimgLobby = (state) => state.datahuay.bgimgLobby;
export const getimgbglogin = (state) => state.datahuay.bgimglogin;
export const getReduxLottoStock = (state) => state.datahuay.lottoStock;
export const getReduxLottoResultYeekee = (state) =>
  state.datahuay.lottoResultYeekee;

export default huaySlice.reducer;
