export const getStorage = (key) => {
  return sessionStorage.getItem(key);
};

export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const setlocalStorage = (key, value) => {
  return localStorage.setItem(key, value);
};

export const timeTwoDigit = (num) => {
  return (num < 10 ? "0" : "") + num;
};

export const getTag = (status, t) => {
  switch (status) {
    case "success":
      return {
        // icon: <CheckCircleOutlined />,
        name: `${t("txt_succeedV2")}`,
        color: "#28a745",
      };
    case "process":
      return {
        // icon: <SyncOutlined spin />,
        name: `${t("txt_process")}`,
        color: "#ffc107",
      };
    case "failed":
      return {
        // icon: <ExclamationCircleOutlined />,
        name: `${t("txt_isnot_success")}`,
        color: "#dc3545",
      };
    case "withdraw_failed":
      return { name: `${t("txt_isnot_success")}`, color: "magenta" };
    case "withdraw_cancel":
      return { name: `${t("txt_cancel")}`, color: "#dc3545" };
    case "withdraw_success":
      return { name: `${t("txt_succeedV2")}`, color: "#28a745" };
    case "withdraw_waiting":
      return { name: `${t("txt_wait_process")}`, color: "gold" };
    default:
      return { name: "-", color: "" };
  }
};

export const getTagLotto = (status, t) => {
  switch (status) {
    case "success":
      return {
        name: `${t("txt_the_prize_has_been_issued")}`,
        color: "#28a745",
      };
    case "cancel":
      return { name: `${t("txt_cancel")}`, color: "#ff4d3b" };
    case "pending":
      return {
        name: `${t("txt_waiting_for_the_prize_drawn")}`,
        color: "#ffc107",
      };
    default:
      return { name: "-", color: "" };
  }
};
